import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../components/Image';
import Icon from '../../components/Icon';
import OutboundLink from '../../components/OutboundLink';
import StudentQuote from '../../components/StudentQuote';
//import IconDownload from '../../components/Icon/icons/Download';
import IconResourcesStoryboard from '../../images/2_annual-challenge/icons/icon-resources-storyboard.svg';
import IconTipsAndTricks from '../../images/2_annual-challenge/icons/icon-resources-tips-tricks.svg';

const ChallengeResources = () => (
  <Layout title="Challenge Resources" className="annual-challenge">
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-orange"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="../../annual-challenge/about-the-challenge/">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb active">Challenge Resources</li>
        </ul>
        <h1 className="page-title">Challenge Resources</h1>
        <Image filename="hero-challenge-resources.png" className="hero-image" />
      </div>
    </div>
    {/* Challenge Resources Intro */}
    <Section>
      <Container>
        <Row>
          <Column size={8} offset={2} className="global-intro">
            <h2>Spark Curiosity, Ignite Innovation</h2>
            <h4>
              Give students a roadmap to real-world problem solving with a
              variety of easy-to-use resources designed to support student
              entries in the 3M Young Scientist Challenge.
            </h4>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Challenge Resources */}
    <Section className="challenge-resources mb-6">
      <Container>
        {/* Row One */}
        <Row className="mb-3">
          {/* Storyboard Template */}
          <Column size={4}>
            <div className="challenge-resource box-shadow hover">
              <div className="challenge-resource__top">
                <span className="challenge-resource__label">
                  Student Resource
                </span>
                <img src={IconResourcesStoryboard} alt="Storyboarding Icon" />
              </div>
              <div className="challenge-resource__content">
                <h3>Storyboard Your Idea</h3>
                <h4>Visualize your ideas with a storyboarding tool.</h4>
                <p>
                  Plan out your idea through simple steps in a storyboard. You
                  don’t have to be a great artist—just use this tool to develop
                  your thoughts freely.
                </p>
              </div>
              <div className="challenge-resource__download">
                <OutboundLink
                  to="/pdfs/2_annual-challenge/3M-YSC-ChallengeResource-Storyboard.pdf"
                  className="challenge-resource__file button"
                  target="_blank"
                >
                  Storyboard Template <Icon name="download" />
                </OutboundLink>
                <span className="challenge-resource__filesize">
                  .PDF, 138KB
                </span>
              </div>
            </div>
          </Column>
          {/* Video Tips and Tricks */}
          <Column size={4}>
            <div className="challenge-resource box-shadow hover">
              <div className="challenge-resource__top">
                <span className="challenge-resource__label">
                  Student Resource
                </span>
                <img src={IconTipsAndTricks} alt="Tips and Trips Icon" />
              </div>
              <div className="challenge-resource__content">
                <h3>Challenge Video Tips and Tricks</h3>
                <h4>Want to know how to create a winning entry? </h4>
                <p>
                  Follow a simple 10-step formula to create your own
                  one-of-a-kind challenge video using this resource.
                </p>
              </div>
              <div className="challenge-resource__download">
                <OutboundLink
                  to="/pdfs/2_annual-challenge/3M-YSC-ChallengeResource-ChallengeVideoTips.pdf"
                  className="challenge-resource__file button"
                  target="_blank"
                >
                  Tips and Tricks <Icon name="download" />
                </OutboundLink>
                <span className="challenge-resource__filesize">
                  .PDF, 230KB
                </span>
              </div>
            </div>
          </Column>
          {/* Challenge Participant Archive */}
          <Column size={4}>
            <div className="challenge-resource box-shadow hover">
              <div className="challenge-resource__top">
                <span className="challenge-resource__label">
                  Student Resource
                </span>
                <Image
                  filename="icon-resources-past-winners.svg"
                  className="challenge-resource__icon"
                />
              </div>
              <div className="challenge-resource__content">
                <h3>Past Winners & Finalists</h3>
                <h4>
                  Meet former challenge participants for the inside scoop.
                </h4>
                <p>
                  Find out how other students from all over the country were
                  able to make it all the way to the finals in the nation’s
                  premier science competition.
                </p>
              </div>
              <div className="challenge-resource__download">
                <Link
                  to="/annual-challenge/finalists-mentors-judges"
                  className="challenge-resource__file button"
                  target="_blank"
                >
                  Challenge Participant Archive <Icon name="arrowright" />
                </Link>
                <span className="challenge-resource__filesize"></span>
              </div>
            </div>
          </Column>
        </Row>
        {/* Row Two */}
        <Row className="mb-3">
          {/* Challenge Project Template */}
          <Column size={4}>
            <div className="challenge-resource box-shadow hover">
              <div className="challenge-resource__top">
                <span className="challenge-resource__label">
                  Student Resource
                </span>
                <Image
                  filename="icon-resources-project-template.svg"
                  className="challenge-resource__icon"
                />
              </div>
              <div className="challenge-resource__content">
                <h3>Project Template</h3>
                <h4>Work through the entry process.</h4>
                <p>
                  Use a series of simple brainstorming exercises to encourage
                  students as they create their challenge submission.
                </p>
              </div>
              <div className="challenge-resource__download">
                <OutboundLink
                  to="/pdfs/2_annual-challenge/3M-YSC-ChallengeResource-ChallengeProjectTemplate.pdf"
                  className="challenge-resource__file button"
                  target="_blank"
                >
                  Project Template <Icon name="download" />
                </OutboundLink>
                <span className="challenge-resource__filesize">
                  .PDF, 241KB
                </span>
              </div>
            </div>
          </Column>
          {/* Innovation Packet  -- HIDING UNTIL ASSETS READY -- TBD*/}
          <Column size={4}>
            <div className="challenge-resource multifile box-shadow hover">
              <div className="challenge-resource__top">
                <span className="challenge-resource__label">
                  Educator Resource
                </span>
                <Image
                  filename="icon-week-of-innovation.svg"
                  className="challenge-resource__icon"
                />
              </div>
              <div className="challenge-resource__content">
                <h3>Week of Innovation</h3>
                <h4>Inspire students to think like a Scientist.</h4>
                <p>
                  Access one-week innovative challenges that will inspire
                  learners to consider how to improve the world around them.
                </p>
              </div>
              <div className="challenge-resource__download first">
                <OutboundLink
                  to="/pdfs/2_annual-challenge/3M-YSC-2024-WeekOfInnovation.pptx"
                  className="challenge-resource__file button"
                  target="_blank"
                >
                  Student Task Card
                  <Icon name="download" />
                </OutboundLink>
                <span className="challenge-resource__filesize">
                  .PPTX, 9.2MB
                </span>
              </div>
              <div className="challenge-resource__download">
                <OutboundLink
                  to="/pdfs/2_annual-challenge/3M-YSC-WeekOfInnovation-EdGuide.pdf"
                  className="challenge-resource__file button"
                  target="_blank"
                >
                  Educator Guide <Icon name="download" />
                </OutboundLink>
                <span className="challenge-resource__filesize">
                  .PDF, 688KB
                </span>
              </div>
            </div>
          </Column>
          {/* 4-Week Roadmap */}
          <Column size={4}>
            <div className="challenge-resource box-shadow hover">
              <div className="challenge-resource__top">
                <span className="challenge-resource__label">
                  Educator Resource
                </span>
                <Image
                  filename="icon-resources-4weekroadmap.svg"
                  className="challenge-resource__icon"
                />
              </div>
              <div className="challenge-resource__content">
                <h3>4-Week Roadmap</h3>
                <h4>Implement the challenge anywhere with this handy tool.</h4>
                <p>
                  Check out this PowerPoint for a breakdown of the challenge
                  entry process in a flexible format that is easy to incorporate
                  into your lessons.
                </p>
              </div>
              <div className="challenge-resource__download">
                <OutboundLink
                  to="/pdfs/2_annual-challenge/3M-YSC-2024-4WeekRoadmap.pptx"
                  className="challenge-resource__file button"
                  target="_blank"
                >
                  4-Week Roadmap <Icon name="download" />
                </OutboundLink>
                <span className="challenge-resource__filesize">
                  .PPT, 10.4MB
                </span>
              </div>
            </div>
          </Column>
        </Row>
        {/* Row Three */}
        <Row className="mb-3">
          {/* Challenge Project Timeline */}
          <Column size={4}>
            <div className="challenge-resource box-shadow hover">
              <div className="challenge-resource__top">
                <span className="challenge-resource__label">
                  Student Resource
                </span>
                <Image
                  filename="icon-resources-project-timeline.svg"
                  className="challenge-resource__icon"
                />
              </div>
              <div className="challenge-resource__content">
                <h3>Project Timeline</h3>
                <h4>
                  Stay up-to-date on important milestones in the challenge.
                </h4>
                <p>
                  Use this streamlined overview of the challenge process to
                  ensure students are on track for successful submissions.
                </p>
              </div>
              <div className="challenge-resource__download multilang">
                <div className="challenge-resource__download-multi">
                  <OutboundLink
                    to="/pdfs/2_annual-challenge/3M-YSC-ChallengeResource-ChallengeTimeline-SPAN.pdf"
                    className="challenge-resource__file button es"
                    target="_blank"
                  >
                    En Español <Icon name="download" />
                  </OutboundLink>
                  <span className="challenge-resource__filesize">
                    .PDF, 253KB
                  </span>
                </div>
                <div className="challenge-resource__download-multi">
                  <OutboundLink
                    to="/pdfs/2_annual-challenge/3M-YSC-ChallengeResource-ChallengeTimeline.pdf"
                    className="challenge-resource__file button"
                    target="_blank"
                  >
                    Project Timeline
                    <Icon name="download" />
                  </OutboundLink>
                  <span className="challenge-resource__filesize">
                    .PDF, 239KB
                  </span>
                </div>
              </div>
            </div>
          </Column>
          {/* Challenge After School Project Timeline */}
          <Column size={4}>
            <div className="challenge-resource box-shadow hover">
              <div className="challenge-resource__top">
                <span className="challenge-resource__label">
                  After-School Resource
                </span>
                <Image
                  filename="icon-lightbulb.svg"
                  className="challenge-resource__icon"
                />
              </div>
              <div className="challenge-resource__content">
                <h3>After School Project Timeline</h3>
                <h4>Support students through the entry process.</h4>
                <p>
                  Encourage students to channel their scientific curiosity into
                  a life changing journey using 9 simple steps designed to
                  streamline the process.
                </p>
              </div>
              <div className="challenge-resource__download multilang">
                <div className="challenge-resource__download-multi">
                  <OutboundLink
                    to="/pdfs/2_annual-challenge/3M-YSC-ChallengeResource-AfterSchoolProjectTimeline-SPAN.pdf"
                    className="challenge-resource__file button es"
                    target="_blank"
                  >
                    En Español <Icon name="download" />
                  </OutboundLink>
                  <span className="challenge-resource__filesize">
                    .PDF, 244KB
                  </span>
                </div>
                <div className="challenge-resource__download-multi">
                  <OutboundLink
                    to="/pdfs/2_annual-challenge/3M-YSC-ChallengeResource-AfterSchoolProjectTimeline.pdf"
                    className="challenge-resource__file button"
                    target="_blank"
                  >
                    After School Project Timeline
                    <Icon name="download" />
                  </OutboundLink>
                  <span className="challenge-resource__filesize">
                    .PDF, 254KB
                  </span>
                </div>
              </div>
            </div>
          </Column>
          {/* Letter to Parents */}
          <Column size={4}>
            <div className="challenge-resource box-shadow hover">
              <div className="challenge-resource__top">
                <span className="challenge-resource__label">
                  Educator Resource
                </span>
                <Image
                  filename="icon-resources-letter.svg"
                  className="challenge-resource__icon"
                />
              </div>
              <div className="challenge-resource__content">
                <h3>Letter to Parents</h3>
                <h4>Scientific exploration beyond the classroom.</h4>
                <p>
                  Start a dialogue with parents and inspire them to support
                  student challenge submissions with an introductory letter
                  covering the basics.
                </p>
              </div>
              <div className="challenge-resource__download multilang">
                <div className="challenge-resource__download-multi">
                  <OutboundLink
                    to="/pdfs/2_annual-challenge/3M-YSC-ChallengeResource-LettertoParents-SPAN.pdf"
                    className="challenge-resource__file button es"
                    target="_blank"
                  >
                    En Español <Icon name="download" />
                  </OutboundLink>
                  <span className="challenge-resource__filesize">
                    .PDF, 126KB
                  </span>
                </div>
                <div className="challenge-resource__download-multi">
                  <OutboundLink
                    to="/pdfs/2_annual-challenge/3M-YSC-ChallengeResource-LettertoParents.pdf"
                    className="challenge-resource__file button"
                    target="_blank"
                  >
                    Read Letter
                    <Icon name="download" />
                  </OutboundLink>
                  <span className="challenge-resource__filesize">
                    .PDF, 126KB
                  </span>
                </div>
              </div>
            </div>
          </Column>
        </Row>
        {/* Row Four */}
        <Row>
          {/* Parent Support Tips */}
          <Column size={4}>
            <div className="challenge-resource box-shadow hover">
              <div className="challenge-resource__top">
                <span className="challenge-resource__label">
                  Parent Resource
                </span>
                <Image
                  filename="icon-resources-desk.svg"
                  className="challenge-resource__icon"
                />
              </div>
              <div className="challenge-resource__content">
                <h3>Parent Support Tips</h3>
                <h4>
                  Ready to guide students on a one–of–a–kind STEM adventure?
                </h4>
                <p>
                  Empower students to channel their scientific curiosity into a
                  life-changing journey using 10 simple tips designed to
                  streamline the process.
                </p>
              </div>
              <div className="challenge-resource__download">
                <OutboundLink
                  to="/pdfs/2_annual-challenge/3M-YSC-ChallengeResource-ParentSupportTips.pdf"
                  className="challenge-resource__file button"
                  target="_blank"
                >
                  Parent Tips <Icon name="download" />
                </OutboundLink>
                <span className="challenge-resource__filesize">
                  .PDF, 250KB
                </span>
              </div>
            </div>
          </Column>
          {/* Certificate of Achievement */}
          <Column size={4}>
            <div className="challenge-resource box-shadow hover">
              <div className="challenge-resource__top">
                <span className="challenge-resource__label">
                  Educator Resource
                </span>
                <Image
                  filename="icon-resources-certificate.svg"
                  className="challenge-resource__icon"
                />
              </div>
              <div className="challenge-resource__content">
                <h3>Certificate of Achievement</h3>
                <h4>Celebrate the innovator in every student.</h4>
                <p>
                  Bring the excitement of problem solving into any learning
                  environment and show students your appreciation for their
                  scientific skills.
                </p>
              </div>
              <div className="challenge-resource__download">
                <OutboundLink
                  to="/pdfs/2_annual-challenge/3M-YSC-ChallengeResource-CertificateofAchievement.pdf"
                  className="challenge-resource__file button"
                  target="_blank"
                >
                  Teacher to Student <Icon name="download" />
                </OutboundLink>
                <span className="challenge-resource__filesize">.PDF, 2MB</span>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Student Stories Quote */}
    <Section className="student-stories">
      <StudentQuote
        image="image-moitri-santra-quote.png"
        name="Moitri Santra"
        year="2021 3M Young Scientist Challenge Finalist "
        body="This challenge was a fantastic opportunity to integrate myself more into the
        world of brain computer interfaces and how they can be applied in early diagnosis
        and treatment."
        path="/annual-challenge/finalists-mentors-judges/finalists/moitri-santra"
        classNames="bg-gradient-orange cta__link no-arrow"
        calloutText="Learn what sparked Moitri’s journey"
      ></StudentQuote>
    </Section>
  </Layout>
);

export default ChallengeResources;
